<template>
    <b-card :title="`${$t('create_permission')}`">
        <form @submit.prevent="createPermission" @keydown="form.onKeydown($event)" class="edit-form" enctype="multipart/form-data">
            <b-row>
                <b-col sm="6">
                    <div class="form-group">
                        <label for="title">{{ $t('permission_title') }}<span class="text-danger">*</span></label>
                        <input type="text" name="title" class="form-control" v-model="form.title" :class="{ 'is-invalid': form.errors.has('title') }">
                        <has-error :form="form" field="title" />
                    </div>
                    <div class="form-group">
                        <label for="name">{{ $t('name') }} eng<span class="text-danger">*</span></label>
                        <input type="text" name="name" class="form-control" v-model="form.name" :class="{ 'is-invalid': form.errors.has('name') }">
                        <has-error :form="form" field="name" />
                    </div>
                </b-col>
                <b-col sm="6"></b-col>
            </b-row>
            <button type="submit" class="btn btn-success" :disabled="form.busy">{{ $t('add') }}</button>
        </form>
    </b-card>
</template>
<script>
import Form from 'vform'
export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('create_permission') }
    },
    data: () => ({
        form: new Form({
            title: '',
            name: ''
        })
    }),
    methods: {
        async createPermission () {
            this.form.busy = true
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            await this.form.post(apiUrl + 'permissions').then(resp => {
                this.$router.push({ name: 'permissions' })
            }).catch(error => {
                this.form.errors.set(error.response.data.error.errors)
            })
        }
    }
}
</script>
